<template>
  <!-- Wrapper Starts -->
  <div class="wrapper">
    <div class="container-fluid user-auth">
      <div class="hidden-xs col-sm-2 col-md-2 col-lg-2"></div>
      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
        <div class="form-container">
          <div>
            <!-- Logo Starts -->
            <center>
              <router-link to="/">
                <img id="logo" height="100" width="100" class="img-responsive mobile-logo" src="images/logo.png"
                  style="margin:0px;padding:0px" alt="logo" />
              </router-link>
            </center>
            <!-- Logo Ends -->

            <!-- Login Section Title Starts -->
            <div v-if="!twoFactorAuthRequired">
              <div class="row text-center">
                <h2 class="title-head hidden-xs">member <span>login</span></h2>
                <p class="info-form">
                  Enter your email and password to continue
                </p>
              </div>

              <!-- Login Form Starts -->
              <form style="text-align: start" @submit.prevent="login">
                <!-- Input Field Starts -->
                <div class="form-group">
                  <input id="email" v-model="$v.email.$model" class="form-control" name="email" placeholder="EMAIL"
                    type="email" />
                  <p v-if="
                                        (!$v.email.required || !$v.email.email) && $v.email.$dirty
                                      " class="text-danger">
                    valid email is required
                  </p>
                </div>
                <!-- Input Field Ends -->
                <!-- Input Field Starts -->
                <div class="form-group">
                  <input id="pwd" v-model="$v.pwd.$model" class="form-control" name="pwd" placeholder="PASSWORD"
                    type="password" required />

                  <p v-if="
                                        (!$v.pwd.minLength || !$v.pwd.required) && $v.pwd.$dirty
                                      " class="text-danger">
                    valid password of min 6 characters is required
                  </p>
                </div>
                <!-- Input Field Ends -->

                <!-- Submit Form Button Starts -->
                <div class="form-group">
                  <!-- Reset Password Link -->
                  <p class="text-center" style="display:flex; align-item:start">
                    <router-link to="password-reset"> Forgot Password </router-link>
                  </p>
                  <button v-if="!isLoading" :disabled="!formValid" class="btn btn-primary" type="submit">
                    login
                  </button>
                  <div class="text-center">
                    <md-progress-spinner v-if="isLoading" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
                  </div>
                  <p class="text-center">
                    don't have an account ?
                    <router-link to="register"> register now </router-link>
                  </p>
                </div>

                <!-- Submit Form Button Ends -->
              </form>
              <!-- Login Form Ends -->
            </div>
            <!-- Login Section Ends Here -->

            <!-- Two Factor Auth Section Title Starts -->
            <div v-if="twoFactorAuthRequired">
              <div class="row text-center">
                <h2 class="title-head hidden-xs">member <span>login</span></h2>
                <p class="info-form">
                  Enter one time pin sent to {{ credencials.email }}
                </p>
              </div>

              <!-- Login Form Starts -->
              <form style="text-align: start" @submit.prevent="verifyOtp">
                <!-- Input Field Starts -->
                <div class="form-group">
                  <input id="email" v-model="$v.otpCode.$model" class="form-control" name="otp" placeholder="OTP CODE"
                    type="number" />
                  <p v-if="!$v.otpCode.required && $v.otpCode.$dirty" class="text-danger">
                    valid otp code is required
                  </p>
                </div>
                <!-- Input Field Ends -->

                <center style="margin:20px; color:">
                  <md-button class="md-dense md-primary" @click="resendOtp" style="width:10px">
                    Resend otp</md-button>
                </center>

                <!-- Input Field Ends -->
                <!-- Submit Form Button Starts -->
                <div class="form-group">
                  <button v-if="!isOtpVerificationLoading" :disabled="!otpFormValid" class="btn btn-primary"
                    type="submit">
                    Verify Otp
                  </button>
                  <div class="text-center">
                    <md-progress-spinner v-if="isOtpVerificationLoading" :md-diameter="20" :md-stroke="3"
                      md-mode="indeterminate" />
                  </div>
                  <p class="text-center">
                    don't have an account ?
                    <router-link to="register"> register now </router-link>
                  </p>
                </div>

                <!-- Submit Form Button Ends -->
              </form>
              <!-- Login Form Ends -->
            </div>
            <!-- Two Factor Auth Section Ends Here -->
          </div>
        </div>
        <!-- Copyright Text Starts -->
        <p class="text-center copyright-text">
          <router-link to="/">SecureAsset</router-link> © {{ year }} All Rights
          Reserved
          <router-link class="primary" to="/terms">Terms of Use</router-link>
          and <router-link to="/privacy">Privacy Policy</router-link>
        </p>
        <!-- Copyright Text Ends -->
      </div>
      <div class="hidden-xs col-sm-2 col-md-2 col-lg-2"></div>
    </div>
  </div>
  <!-- Wrapper Ends -->
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import Http from "../../helpers/http";
import { HOST } from "../../store/global";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      pwd: "",
      otpCode: "",
      credencials: { email: "" },
      twoFactorAuthRequired: false,
      isLoading: false,
      isOtpVerificationLoading: false,
    };
  },
  async mounted() {
    const user = this.$store.state.currentUser;

    if (user) {
      return this.$router.replace("home");
    }
  },
  computed: {
    formValid() {
      return !this.$v.email.$invalid && !this.$v.pwd.$invalid;
    },
    otpFormValid() {
      return !this.$v.otpCode.$invalid && !this.$v.otpCode.$invalid;
    },

    year() {
      return new Date().getUTCFullYear();
    },
  },
  methods: {
    async login() {
      this.isLoading = true;
      const credencial = { email: this.email, pwd: this.pwd };
      const res = await this.$store.dispatch("login", credencial);
      this.isLoading = false;

      if (!res.status) {
        return this.showMsg(
          res.message || "error occurred, check your internet connection"
        );
      }

      // if two factor auth is diabled
      if (!res.data.tfa) {
        this.onSuccessfulLogin(res.data);
      }

      // if two factor auth is enabaled
      if (res.data.tfa) {
        this.credencials = res.data;
        this.twoFactorAuthRequired = true;
      }
    },
    async verifyOtp() {
      const onComeplete = () => {
        this.isOtpVerificationLoading = false;
        this.onSuccessfulLogin(this.credencials);
      };

      const onFail = (err) => {
        console.log(err);
        this.isOtpVerificationLoading = false;
      };

      try {
        if (!this.otpCode || this.otpCode.lenght < 6 || this.otpCode.lenght > 6)
          return;

        const url = `${HOST}/otp/validate`;

        const payload = {
          code: this.otpCode,
          email: this.credencials.email,
        };

        this.isOtpVerificationLoading = true;

        const req = await Http({ method: "POST", url, body: payload });
        this.isOtpVerificationLoading = false;

        console.log(req);

        if (!req.status) {
          return this.showMsg(req.message);
        }
        onComeplete();
      } catch (error) {
        onFail(error);
      }
    },

    onSuccessfulLogin(data) {
      this.showMsg("Success, Welcome " + data.firstName);

      this.$store.commit("setUser", data);

      if (data.isAdmin === true) {
        return this.$router.push("admin");
      }
      this.$router.push("home");
    },

    async resendOtp() {
      const body = { email: this.credencials.email };

      const url = `${HOST}/otp/send`;

      this.isOtpVerificationLoading = true;

      const req = await Http({ method: "POST", url, body });

      this.isOtpVerificationLoading = false;

      this.showMsg(req.message);
    },

    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },
    closeMsg() {
      this.$store.commit("showMsg", "");
    },
  },

  validations: {
    email: {
      required,
      email,
      minLenght: minLength(3),
    },
    pwd: {
      required,
      minLength: minLength(6),
    },
    otpCode: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
};
</script>
